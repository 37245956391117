import React from 'react';

import { Link, Route } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import CommunSVGs from 'svgs/commun';

type Props = {
  location: Object,
  history: Object,
};

const NoMatch404 = ({ location, history }: Props) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = 404;
        return (
          <div className="Page Page-full NoMatch404 text-center">
            <h1 className="h4 p-5">
              Page not found <code>{location.pathname}</code>
            </h1>
            <img src="/assets/images/error_404.svg" alt="flowfo 404" width="400" />
            <div className="text-center">
              <Link to="/discover" className="Tab-link text-primary flowfont-medium fs-113">
                <CommunSVGs.Discover width="20px" height="20px" fill="var(--primary)" className="mr-2" />
                Find inspiring Artworks
              </Link>
              <br />
              <Button variant="link" onClick={history.goBack}>
                <CommunSVGs.ArrowLeft width="16px" height="16px" fill="#EDEDED" /> Go back
              </Button>
            </div>
          </div>
        );
      }}
    />
  );
};

export default React.memo(NoMatch404);
